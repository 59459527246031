<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h4 class="m-0 text-dark">Return Order of <b>{{ merchant.business }}</b></h4>
                         <img id="logoId" src="../../../public/images/logo.png" style="width:40px; height:40px; display:none;">
                    </div>
                    <div class="col-md-2">
                        <div class="form-group" style="margin-top:28px;">
                            <button class="ml-1 btn btn-success btn-sm mr-2" @click="pdfDownload">PDF <i class="fa fa-download"></i></button>
                            <button class="btn btn-warning btn-sm" >                                            
                                <export-excel
                                    :data="dataCustomize"
                                    :fields= "json_fields"
                                    >
                                    Excel <i class="fa fa-download"></i>
                                </export-excel>
                            </button>
                        </div>
                    </div>
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table id="exportPdfTable" class="table table-bordered table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th>Order ID</th>
                                                    <th>Ref ID</th>
                                                    <th>Entry Date</th>
                                                    <th>Lasst Action Date</th>
                                                    <th>Name</th>
                                                    <th>Mobile</th>
                                                    <th>Price (BDT)</th>
                                                    <th>Reason</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(order, index) in orders" :key="index">
                                                    <td>{{ order.tracking_id }}</td>
                                                    <td @click="orderLog(order.logs)">{{ order.ref_id }}</td>
                                                    <td>{{ order.date | dateformat }}</td>
                                                    <td>{{ order.activity_date | dateformat }}</td>
                                                    <td>{{ order.name }}</td>
                                                    <td>{{ order.mobile }}</td>
                                                    <td>{{ order.price }}</td>
                                                    <td>{{ order.reason }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
        <!-- /.content -->
        <div v-if="orderLogModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-info text-white">
                        <h5 class="modal-title text-center w-100">Order Log</h5>
                        <button type="button" class="close" aria-label="Close" @click="cancelModal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <table class="table table-bordered table-sm">
                            <tr>
                                <th style="width:20%">Action By</th>
                                <th style="width:55%">Action</th>
                                <th style="width:25%">Date & Time</th>
                            </tr>
                            <tr v-for="(log, index) in logs" :key="index">
                                <td>{{ log.operation_by }}</td>
                                <td>{{ log.operation }}</td>
                                <td>{{ log.created_at | dateformat }} at {{ log.created_at | timeformat }}</td>
                            </tr>
                        </table>                      
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm btn-danger" @click="cancelModal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import config from '@/config'
    // import jsPDF from 'jspdf'
    import 'jspdf-autotable'    
    import ExportPdf from './ReturnPdf'

    const excelColumn = {
        'Order ID': 'order_id',
        'Ref. ID': 'order_ref_id',
        'Entry Date': 'order_date',
        'Last Action Date': 'order_activity_date',
        'Name': 'order_name',
        'Mobile': 'order_mobile',
        'Price': 'order_price',
        'Reason': 'order_reason',
        'Signature': 'order_sign'
    }
    export default {
        name:'MakePaymentOrder',
        data(){
            return {   
                orderLogModal:false,
                logs:[],
                loader: true,             
                orders:[],
                key:'',
                merchant: '',
                rider: ''
            }
        },
        created(){
            this.getOrders();
        },
        computed: {
            json_fields: function () {
                return excelColumn
            },
            dataCustomize () {
                return this.orders.map(item => {
                    item.order_id       = item.tracking_id,
                    item.order_ref_id   = item.ref_id,
                    item.order_date     = item.date,
                    item.order_activity_date = item.activity_date,
                    item.order_name     = item.name,
                    item.order_mobile   = item.mobile,
                    item.order_price    = item.price,
                    item.order_reason   = item.reason,
                    item.order_sign     = ''

                    return Object.assign({}, item)
                })
            }
        },
        methods:{
            searchData () {
                this.getOrders()
            },
            async getOrders(){     
                this.loader = true     
                const response = await config.getData(`/return/details/${this.$route.query.id}`)
                this.loader = false
                if (response.success){
                    this.orders = response.data
                    this.merchant = response.merchant
                    this.rider = response.rider
                } else {
                    this.orders = []
                    this.merchant = ''
                    this.rider = ''
                } 
            },
            pdfDownload() {
                // this.loader = true
                const base64Logo = this.getBase64Logo(document.getElementById('logoId'))
                ExportPdf.returnPdfDetails(base64Logo, this.orders, this.merchant, this.rider)
                // this.loader = false
            },
            getBase64Logo (logo) {
                var canvas = document.createElement('canvas')
                canvas.width = logo.width
                canvas.height = logo.height
                var ctx = canvas.getContext('2d')
                ctx.drawImage(logo, 0, 0)
                var dataURL = canvas.toDataURL('image/png')
                return dataURL.replace(/^data:image\/(png|jpg);base64,/, '')
            },
            orderLog (logs) {
                this.orderLogModal = true
                this.logs = logs
            },
            cancelModal () {
                this.orderLogModal = false
            }
        }
    }
</script>

<style scoped>
    .d-inline {
        display: inline-block;
        padding-left: 3px;
    }
</style>